import { Link } from "gatsby"
import * as React from "react"
import GradientJumbo from "../../components/gradient-jumbo"
import Layout from "../../components/layout"
import Contact from "../../components/sections/contact"
import Seo from "../../components/seo"

const WikiPage = props => (
  <Layout language="en" pathname={props.location.pathname}>
    <Seo
      title="Wiki"
      lang="en"
      description="This is the CAMARADES Wiki for Preclinical Systematic Reviews & Meta-Analysis, where you can find informations, links, articles and other useful tools to guide you in your systematic review"
    />
    <GradientJumbo subtitle="Wiki" title="A manual for systematic review and preclinical meta-analysis" />
    <section className="container">
      <h3 className="fw-bold">
        The CAMARADES BR's Wiki guide is only available in <u>Portuguese</u>
      </h3>
      <p>
        You can check out the portuguese version{" "}
        <Link to="/wiki">right here</Link>.
      </p>
      <p>
        If you are looking for the CAMARADES DE's English version you can click{" "}
        <a href="http://camarades.de" target="_blank" rel="noopener noreferrer">
          here
        </a>
        .
      </p>
    </section>
    <Contact language="en" />
  </Layout>
)

export default WikiPage
